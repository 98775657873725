import classNames from "classnames";
import React from "react";
import { Link } from "../link";
import MagazineSearchbox from "./magazine-searchbox";

const removeCategoryLink = (location, cat) => {
  const currentParams = new URLSearchParams(location.search);
  const params = new URLSearchParams(
    [...currentParams.entries()].filter(
      ([key, value]) => key !== "category" || value !== cat.toLowerCase()
    )
  );
  if (params.has("page")) {
    params.delete("page");
  }
  return `?${params.toString()}`;
};
const addCategoryLink = (location, cat) => {
  const params = new URLSearchParams(location.search);
  params.append("category", cat.toLowerCase());
  if (params.has("page")) {
    params.delete("page");
  }
  return `?${params.toString()}`;
};

const MagazineFilters = ({ filteredCategories, location }) => {
  return (
    <div className="magazine__filters d-none d-lg-inline-block">
      <MagazineSearchbox location={location} />
      <div className="magazine__filter-box">
        <div className="magazine__filter-title">Categorie</div>
        <div className="magazine__filter-cat">
          <div className="magazine__filter-cat-remove">
            <ul className="d-flex flex-wrap m-0">
              {Object.keys(filteredCategories)
                ?.filter((cat) => filteredCategories[cat])
                ?.map((cat, i) => (
                  <li key={i} className="magazine__filter-li">
                    <Link
                      to={removeCategoryLink(location, cat)}
                      className={classNames(
                        "magazine__filter-cat-link",
                        `magazine__filter-cat-link--${cat.toLowerCase()}`
                      )}
                      value={cat}
                      onClick={() => window.scrollTo(0, 0, "smooth")}
                    >
                      {cat}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
          <div className="magazine__filter-cat-add">
            <ul className="d-flex flex-wrap m-0">
              {Object.keys(filteredCategories)
                ?.filter((cat) => !filteredCategories[cat])
                ?.map((cat, i) => (
                  <li key={i} className="magazine__filter-li">
                    <Link
                      to={addCategoryLink(location, cat)}
                      className={classNames(
                        "magazine__filter-cat-link",
                        `magazine__filter-cat-link--${cat.toLowerCase()}`
                      )}
                      value={cat}
                      onClick={() => window.scrollTo(0, 0, "smooth")}
                    >
                      {cat}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MagazineFilters;
