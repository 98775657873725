import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Icon from "../../components/elements/icon-svg";
import { navigate } from "gatsby";

const MagazineSearchbox = ({ location, magazineDetail = false }) => {
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      search: new URLSearchParams(location.search)?.get("search") || "",
    },
  });

  const submit = (values) => {
    if (values.search?.length) {
      const params = new URLSearchParams(location.search);
      params.set("search", values.search);
      if (params.has("page")) {
        params.delete("page");
      }
      window.scrollTo(0, 0, "smooth");
      navigate(`${magazineDetail ? "/magazine/" : ""}?${params.toString()}`, {
        state: { search: true },
      });
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setValue("search", params.get("search"));
  }, [location, setValue]);

  return (
    <div className="magazine__filter-box">
      <div className="magazine__filter-title">Cerca</div>
      <div className="magazine__search">
        <form onSubmit={handleSubmit(submit)} noValidate>
          <div className="d-flex align-items-strech">
            <label htmlFor="search" className="d-none">
              Cerca
            </label>
            <input
              type="text"
              {...register("search", {
                required: true,
              })}
              id="search"
              className="magazine__input-text"
              placeholder="Cerca qualcosa"
            />
            <div className="magazine__search-container">
              <button className="magazine__search-btn h-100" type="submit" aria-label="Cerca">
                <Icon name="search" />
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MagazineSearchbox;
