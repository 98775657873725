import React, { useState } from "react";
import Slider from "react-slick";
import classNames from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "../../components/link";
import Icon from "../../components/elements/icon-svg";
import withPreview from "../../utility/with-preview";

const categoryLink = (cat) => {
  const params = new URLSearchParams();
  if (cat !== "Tutti") {
    params.set("category", cat.toLowerCase());
  }
  return `?${params.toString()}`;
};

const Post = ({ data: post, isDragging }) => (
  <div className="magazine-header__slide">
    <Link to={post?.link} onClick={(e) => isDragging && e.preventDefault()}>
      {post?.mainImage?.node && (
        <GatsbyImage
          image={getImage(post.mainImage.node)}
          alt={post?.mainTitle?.value}
          className="magazine-header__slide-bg"
        />
      )}
      <div className="wrapper-container magazine-header__post-text-wrapper">
        <div className="row">
          <div className="col-12">
            <div
              className={classNames(
                "magazine-header__post-text",
                isDragging && "magazine-header__post-text--moving"
              )}
            >
              <div className="magazine-header__post-cat d-lg-none">
                {post?.liferayFields?.taxonomyCategoryNames}
              </div>
              {post?.mainTitle?.value && (
                <div className="magazine-header__post-title">
                  {post.mainTitleText.length > 50
                    ? `${post.mainTitleText.slice(0, 49)}...`
                    : post.mainTitleText}
                </div>
              )}
              {post?.abstract?.value && (
                <div className="magazine-header__post-descr d-none d-md-block">
                  {post.abstractText.length > 137
                    ? `${post.abstractText.slice(0, 136)} [...]`
                    : post.abstractText}
                </div>
              )}
              <div className="magazine-header__post-link">
                Leggi il post <Icon name="long-arrow-right-solid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  </div>
);

const HeaderPost = withPreview(Post);

const MagazineHeader = ({ posts, filteredCategories, mobileView, isFiltered }) => {
  const category =
    Object.keys(filteredCategories)?.find((cat) => filteredCategories[cat]) || "Tutti";

  const categories = Object.keys(filteredCategories);
  categories.unshift("Tutti");

  const [isDragging, setIsDragging] = useState(false);

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    beforeChange: () => setIsDragging(true),
    afterChange: () => setIsDragging(false),
    customPaging: (i) => {
      return (
        <span>
          <button>{i}</button>
          <span className="slick-dots-text">{posts?.[i]?.shortTitle?.value}</span>
        </span>
      );
    },
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          infinite: false,
          slidesToScroll: 0,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="magazine-header__hero">
      {!isFiltered && (
        <div className="magazine-header__hero-text d-none d-lg-block">
          <div className="magazine-header__hero-title">Magazine</div>
          <div className="magazine-header__hero-desc">
            <p>
              Il magazine di ENGIE che ti tiene sempre informato sul mondo dell'energia, della
              sostenibilità e del risparmio.
            </p>
          </div>
          <div className="magazine-header__hero-social">
            <Link
              to="https://www.facebook.com/engieitalia"
              title="Facebook"
              target="_blank"
              className="magazine__social-icon"
            >
              <Icon name="facebook-solid" />
            </Link>
            <Link
              to="https://twitter.com/ENGIEitalia"
              title="Twitter"
              target="_blank"
              className="magazine__social-icon"
            >
              <Icon name="twitter-x" />
            </Link>
          </div>
        </div>
      )}
      <div
        className={classNames(
          "magazine-header__categories d-flex",
          isFiltered && "magazine-header__categories--filtered"
        )}
      >
        {isFiltered && (
          <div className="magazine-header__back text-center d-none d-lg-block">
            <Link className="magazine-header__back-btn" to="/magazine/">
              <Icon name="long-arrow-right-solid" />
              Indietro
            </Link>
          </div>
        )}
        {categories?.map((cat, i) => (
          <div
            className={classNames("magazine-header__categories-cat", i === 0 && "d-lg-none")}
            key={i}
          >
            <Link
              to={categoryLink(cat)}
              className={classNames(
                "magazine-header__cat-link",
                category === cat && "magazine-header__cat-link--selected"
              )}
            >
              {cat}
            </Link>
          </div>
        ))}
      </div>
      {!isFiltered && (
        <div
          className={classNames("magazine-header__main-posts", !mobileView && "d-none d-lg-block")}
        >
          <Slider className="magazine-header__slider" {...settings}>
            {posts?.slice(0, 4)?.map((post, i) => (
              <HeaderPost data={post} key={i} isDragging={isDragging} />
            ))}
          </Slider>
        </div>
      )}
    </div>
  );
};

export default MagazineHeader;
