import React, { useEffect, useState, useMemo } from "react";
import { graphql } from "gatsby";
import { Index } from "flexsearch";
import Layout from "../components/layout";
import { Link } from "../components/link";
import Seo from "../components/seo";
import MagazineHeader from "../components/magazine/magazine-header";
import MagazineFilters from "../components/magazine/magazine-filters";
import MagazinePosts from "../components/magazine/magazine-posts";
import MagazineHighlights from "../components/magazine/magazine-highlights";
import useInitDataLayer from "../utility/useInitDataLayer";
import { useHandleRecaptchaBadge } from "../utility/useHandleRecaptchaBadge";

const MagazinePage = ({ data, location }) => {
  const headerPosts = data.headerPosts.nodes;
  const bestPosts = data.bestPosts.nodes;
  const allPosts = data.allLiferayMagazineStructure.nodes;
  useInitDataLayer(location);
  useHandleRecaptchaBadge(false);

  const allCAtegories = useMemo(
    () =>
      Object.fromEntries(
        [...new Set(allPosts?.map((item) => item?.liferayFields?.taxonomyCategoryNames?.[0]))]
          .sort((a, b) => {
            return a < b ? -1 : a > b ? 1 : 0;
          })
          ?.map((cat) => [cat, false])
      ),
    [allPosts]
  );
  const [categories, setCategories] = useState(allCAtegories);
  const [isFiltered, setIsFiltered] = useState(false);
  const [viewAllMobile, setViewAllMobile] = useState(false);

  const [searchIndex, setSearchIndex] = useState();
  const [postsMap, setPostsMap] = useState();
  const [searchResults, setSearchResults] = useState(location?.state?.search ? [] : undefined);

  const [tags, setTags] = useState();

  const [page, setPage] = useState();

  const [author, setAuthor] = useState();

  useEffect(() => {
    const populateIndex = async () => {
      const index = new Index({
        tokenize: "full",
      });

      try {
        const response = await fetch(`/magazine-flexsearch-index.json`);
        if (!response.ok) {
          throw new Error(
            `Error calling /magazine-flexsearch-index.json ${response.status} ${response.statusText}`
          );
        }
        const { exportList } = await response.json();

        exportList?.forEach((item) => {
          index.import(item.key, item.data);
        });
        //console.log("index import succedeed");
      } catch (err) {
        console.error("Error loading search index: ", err);
      }

      const documentsMap = allPosts.reduce((res, post) => {
        const id = parseInt(post.liferayFields.articleId);
        res.set(id, post);
        return res;
      }, new Map());

      setPostsMap(documentsMap);
      setSearchIndex(index);
    };
    populateIndex();
  }, [allPosts]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has("category")) {
      const filters = [...params]
        .filter(([key, value]) => key === "category")
        .map(([key, value]) => value);
      setIsFiltered(true);
      setViewAllMobile(true);
      setCategories(
        Object.fromEntries(
          Object.keys(allCAtegories).map((cat) => [cat, filters.includes(cat.toLowerCase())])
        )
      );
    } else {
      setCategories(Object.fromEntries(Object.keys(allCAtegories).map((cat) => [cat, false])));
      setIsFiltered(false);
      setViewAllMobile(false);
    }
    if (params.has("search")) {
      if (searchIndex && postsMap) {
        const searchQuery = params.get("search");
        const processSearchResults = async (searchQuery) => {
          const results = await searchIndex.searchAsync(searchQuery, 100);
          const resMap = new Map();
          const resFiltered = [];
          for (let item of results) {
            if (!resMap.has(item)) {
              resFiltered.push(postsMap.get(item));
              resMap.set(item, true);
            }
          }
          //console.log(`Found ${resFiltered.length} results`);
          setSearchResults(resFiltered);
        };
        processSearchResults(searchQuery);
      }
    } else {
      setSearchResults(null);
    }
    if (params.has("tag")) {
      const filters = [...params]
        .filter(([key, value]) => key === "tag")
        .map(([key, value]) => value);
      setIsFiltered(true);
      setViewAllMobile(true);
      setTags(filters);
    } else {
      setTags();
    }
    if (params.has("page")) {
      const page = [...params]
        .filter(([key, value]) => key === "page")
        .map(([key, value]) => value)?.[0];
      setPage(Number(page));
    } else {
      setPage(1);
    }
    if (params.has("author")) {
      const author = [...params]
        .filter(([key, value]) => key === "author")
        .map(([key, value]) => value)?.[0];
      setAuthor(author);
    } else {
      setAuthor();
    }
  }, [location.search, searchIndex, postsMap, allPosts, allCAtegories]);

  return (
    <Layout location={location} showChatWidget={false}>
      <Seo title="ENGIE Magazine - Il magazine di ENGIE" path="/magazine/" />
      <div className="magazine">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="magazine-detail__breadcrumb mb-0 d-flex flex-wrap d-lg-none">
                <ol className="magazine-detail__breadcrumb-list">
                  <li className="magazine-detail__breadcrumb-list-item">
                    <Link to="/" className="magazine-detail__breadcrumb-link">
                      Casa Engie
                    </Link>
                    {" > "}
                  </li>
                  <li className="magazine-detail__breadcrumb-list-item">
                    <span className="magazine-detail__breadcrumb-current">Magazine</span>
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <MagazineHeader
            posts={headerPosts}
            filteredCategories={categories}
            mobileView={!viewAllMobile}
            isFiltered={isFiltered || searchResults}
            location={location}
          />
          {!viewAllMobile && !isFiltered && (
            <div className="row d-lg-none">
              <div className="col-12 text-center">
                <button
                  className="magazine__see-more"
                  onClick={() => {
                    setViewAllMobile(true);
                    window.scrollTo(0, 0, "smooth");
                  }}
                >
                  Vedi tutti
                </button>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <div className="magazine__sticky-container d-flex align-items-start">
                <MagazineFilters filteredCategories={categories} location={location} />
                <MagazinePosts
                  posts={searchResults ? searchResults : allPosts}
                  filteredCategories={categories}
                  tags={tags}
                  mobileView={viewAllMobile}
                  location={location}
                  page={page}
                  author={author}
                />
              </div>
            </div>
          </div>
          <div className="row d-lg-none">
            <div className="col-12">
              <MagazineHighlights posts={bestPosts} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MagazinePage;

export const query = graphql`
  query {
    headerPosts: allLiferayMagazineStructure(
      sort: { fields: [liferayFields___dateModified, liferayFields___title], order: DESC }
      limit: 4
    ) {
      nodes {
        id
        liferayFields {
          taxonomyCategoryNames
          articleId
          siteId
        }
        link
        mainTitle {
          value
        }
        mainTitleText
        shortTitle {
          value
        }
        mainImage {
          node {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        abstract {
          value
        }
        abstractText
      }
    }
    bestPosts: allLiferayMagazineStructure(
      sort: { fields: [liferayFields___dateModified, liferayFields___title], order: DESC }
      limit: 20
    ) {
      nodes {
        id
        liferayFields {
          taxonomyCategoryNames
          articleId
          siteId
        }
        link
        mainTitle {
          value
        }
        mainTitleText
        shortTitle {
          value
        }
        abstract {
          value
        }
        abstractText
        smallCardImage {
          node {
            gatsbyImageData(width: 510)
          }
        }
      }
    }
    allLiferayMagazineStructure(sort: { fields: [liferayFields___dateCreated], order: DESC }) {
      nodes {
        id
        liferayFields {
          title
          taxonomyCategoryNames
          keywords
          articleId
          siteId
        }
        link
        authorName {
          value
        }
        mainTitle {
          value
        }
        mainTitleText
        smallCardImage {
          node {
            gatsbyImageData(width: 510)
          }
        }
        mediumCardImage {
          node {
            gatsbyImageData(width: 510)
          }
        }
        largeCardImage {
          node {
            gatsbyImageData(width: 1040)
          }
        }
      }
    }
  }
`;
