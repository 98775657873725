import React from "react";
import Slider from "react-slick";
import classNames from "classnames";
import { Link } from "../link";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Icon from "../elements/icon-svg";

const settings = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  dots: false,
  arrows: false,
};
const MagazineHighlights = ({ posts }) => {
  return (
    <div className="magazine-highlights">
      <div className="magazine-highlights__title">Più letti</div>
      <Slider className="magazine-highlights__slider" {...settings}>
        {posts.map((post, i) => (
          <div className={classNames("magazine-highlights__slide")} key={post?.id}>
            <Link to={post.link} className="magazine-highlights__main-link">
              {post?.smallCardImage?.node && (
                <GatsbyImage
                  image={getImage(post.smallCardImage.node)}
                  alt={post?.mainTitle?.value}
                  className="magazine-highlights__img"
                />
              )}
              <div className="magazine-highlights__text-wrapper">
                <div className="magazine-highlights__post-title">{post?.mainTitle?.value}</div>
                <div className="magazine-highlights__link">
                  <Icon name="long-arrow-right-solid" />
                </div>
              </div>
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default MagazineHighlights;
