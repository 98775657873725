import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import { Link } from "../link";
import Icon from "../elements/icon-svg";
import classNames from "classnames";
import withPreview from "../../utility/with-preview";

const PostItem = ({ data, type = "small" }) => {
  return (
    <div
      className={classNames("magazine-posts__post", type && `magazine-posts__post--${type}`)}
      key={data?.id}
    >
      <Link to={data.link}>
        {((type === "small" && data?.smallCardImage?.node) ||
          (type === "vertical" && data?.mediumCardImage?.node) ||
          (type === "horizontal" && data?.largeCardImage?.node)) && (
          <GatsbyImage
            image={getImage(
              type === "small"
                ? data.smallCardImage.node
                : type === "vertical"
                ? data.mediumCardImage.node
                : data.largeCardImage.node
            )}
            alt={data?.mainTitle?.value}
            className={classNames(
              "magazine-posts__img",
              data?.liferayFields?.taxonomyCategoryNames?.[0] &&
                `magazine-posts__img--${data.liferayFields.taxonomyCategoryNames[0].toLowerCase()}`
            )}
          />
        )}
        <div
          className={classNames(
            "magazine-posts__text-wrapper",
            data?.liferayFields?.taxonomyCategoryNames?.[0] &&
              `magazine-posts__text-wrapper--${data.liferayFields.taxonomyCategoryNames[0].toLowerCase()}`
          )}
        >
          <div className="magazine-posts__cat">{data?.liferayFields?.taxonomyCategoryNames}</div>
          <div className="magazine-posts__title">
            {data?.mainTitle?.value &&
              (data.mainTitleText.length > 70
                ? `${data.mainTitleText.slice(0, 69)}...`
                : data.mainTitle.value)}
          </div>
          <div className="magazine-posts__link">
            Leggi il post <Icon name="long-arrow-right-solid" />
          </div>
        </div>
      </Link>
    </div>
  );
};

const nextPageLink = (location, destPage) => {
  const params = new URLSearchParams(location?.search);
  params.set("page", destPage);
  return `?${params.toString()}`;
};

const Post = withPreview(PostItem);

const MagazinePosts = ({ posts, filteredCategories, tags, mobileView, location, page, author }) => {
  const cats = Object.keys(filteredCategories).filter((cat) => filteredCategories[cat]);
  const filteredPosts = posts
    .filter((post) =>
      cats.length > 0 ? cats.includes(post.liferayFields.taxonomyCategoryNames[0]) : post
    )
    .filter((post) =>
      tags ? tags.filter((tag) => post.liferayFields.keywords.includes(tag))?.length > 0 : post
    )
    .filter((post) => (author ? post?.authorName?.value === author : post));

  const groupedPosts = [...Array(Math.ceil(filteredPosts.length / 8)).keys()].map((x) =>
    filteredPosts.slice(x * 8, x * 8 + 8)
  );

  return (
    <div
      className={classNames("magazine-posts d-lg-inline-block", mobileView ? "d-block" : "d-none")}
    >
      {groupedPosts.slice(0, page).map((group, j) => (
        <div key={j}>
          {group?.[0] && (
            <div className="row">
              <div className="col-md-6">
                <Post data={group[0]} />
                {group?.[1] && <Post data={group[1]} />}
              </div>
              {group?.[2] && (
                <div className="col-md-6">
                  <Post data={group[2]} type="vertical" />
                </div>
              )}
            </div>
          )}
          {group?.[3] && (
            <div className="row">
              <div className="col-md-12">
                <Post data={group[3]} type="horizontal" />
              </div>
            </div>
          )}
          {group?.[4] && (
            <div className="row">
              <div className="col-md-6">
                <Post data={group[4]} type="vertical" />
              </div>
              {group?.[5] && (
                <div className="col-md-6">
                  <Post data={group[5]} />
                  {group?.[6] && <Post data={group[6]} />}
                </div>
              )}
            </div>
          )}
          {group?.[7] && (
            <div className="row">
              <div className="col-md-12">
                <Post data={group[7]} type="horizontal" />
              </div>
            </div>
          )}
          {page < groupedPosts.length && j + 1 === page && (
            <div className="row">
              <div className="col-12 text-center scroll">
                <Link to={nextPageLink(location, page + 1)} className="magazine__see-more">
                  Carica altro
                </Link>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default MagazinePosts;
